<template lang="pug">
q-page.text-center.overflow-hidden
  //- Countdown(v-on:submit="startLogin")   
  .row.justify-center.q-pa-md.gt-xs
    .col-12.col-md-9
      .column
        .col-auto.q-mt-xl.q-mb-xl
          img(
            src="~@/assets/img/logo_green.webp",
            style="width: 280px; height: 100px; max-width: 90vw",
            alt="Limitless logo"
          )
          .text-caption.q-py-sm {{ $t("from") }}
          a(target="_BLANK", href="https://solferinoacademy.com")
            img(
              src="~@/assets/img/solferino.svg",
              style="height: 30px; width: 120px",
              alt="Solferino Academy logo"
            )
        .col-auto.q-mt-xl.q-pb-lg
          .text-body1 {{ $t("calling-all-youth-red-cross-red-crescent-volunteers") }}
        .col-auto
          .text-h1.responsive
            span {{ $t("join-thousands-of-youth-innovators-tackling") }}&nbsp;
            span.text-primary {{ $t("climate-and-environmental-crises") }}
        .col-auto
          .q-mb-xl.q-mt-lg
            q-btn.q-mx-sm.q-mt-sm(
              align="between",
              no-caps,
              color="primary",
              outline,
              size="lg",
              to="/showcase",
              unelevated,
              style="max-width: 90vw; width: 400px",
              :icon-right="matNorthEast"
            ) {{ $t("showcase") }}
            q-btn.q-mt-sm.q-mx-sm(
              align="between",
              no-caps,
              color="primary",
              size="lg",
              to="/academy",
              unelevated,
              style="max-width: 90vw; width: 400px",
              :icon-right="matNorthEast"
            ) {{ $t("find-out-more") }}
  .row.justify-center.q-pa-md.lt-sm(style="max-height: 65vh")
    .col-12.col-md-9
      .column
        .col-auto.q-mt-md.q-mb-lg
          img(
            src="~@/assets/img/logo_green.webp",
            style="max-width: 280px; height: 50px",
            alt="Limitless logo"
          )
          .text-caption.q-py-sm {{ $t("from") }}
          a(target="_BLANK", href="https://solferinoacademy.com")
            img(
              src="~@/assets/img/solferino.svg",
              style="height: 20px; width: 120px",
              alt="Solferino Academy logo"
            )
        .col-auto
          .text-body1.q-mb-md {{ $t("calling-all-youth-red-cross-red-crescent-volunteers") }}
        .col-auto
          .text-h2.responsive
            span {{ $t("join-thousands-of-youth-innovators-tackling") }}
            span.text-primary {{ $t("climate-and-environmental-crises") }}
        .col-auto
          .q-mb-xl.q-mt-lg
            q-btn.q-mx-sm.q-mt-sm(
              align="between",
              no-caps,
              color="primary",
              outline,
              size="lg",
              to="/showcase",
              unelevated,
              style="max-width: 90vw; width: 400px",
              :icon-right="matNorthEast"
            ) {{ $t("showcase") }}
            q-btn.q-mt-sm(
              align="between",
              no-caps,
              color="primary",
              size="lg",
              to="/academy",
              unelevated,
              style="max-width: 90vw; width: 400px",
              :icon-right="matNorthEast"
            ) {{ $t("find-out-more") }}

  .row.justify-center.q-mt-lg
    .col-auto(style="position: relative")
      .herobg

      h1.hero1
        .row.full-height.items-center.justify-center
          .col-auto {{ $t("the-future") }}<br /> {{ $t("is-limitless") }}

  .row.justify-center.q-my-xl(v-if="hasLaunchVideo")
    .col-11.col-md-8
      .q-pt-lg
        q-responsive(:ratio="16 / 9")
          video(controls)
            source(:src="launchVideoSrc")

  .row.justify-center.q-mt-xl
    .col-11.col-md-8
      h2 {{ $t("join-academy") }}

  .q-ma-dynamic
    .row.justify-center
      .col-12.col-md-10
        .row.justify-center.q-col-gutter-lg
          .col-6.col-md
            q-img(
              src="/img/pic1.webp",
              :ratio="1",
              contain,
              style="max-height: 200px"
            )
            .text-body2 {{ $t("learn-about-innovation-frameworks-and-techniques") }}
          .col-6.col-md
            q-img(
              src="/img/pic2.webp",
              :ratio="1",
              contain,
              style="max-height: 200px"
            )
            .text-body2 {{ $t("get-funding-to-develop-and-test-your-idea") }}
          .col-6.col-md
            q-img(
              src="/img/pic3.webp",
              :ratio="1",
              contain,
              style="max-height: 200px"
            )
            .text-body2 {{ $t("join-a-global-network-of-innovators") }}
          .col-6.col-md
            q-img(
              src="/img/pic4.webp",
              :ratio="1",
              contain,
              style="max-height: 200px"
            )
            .text-body2 {{ $t("become-a-certified-international-federation-of-red-cross-and-red-crescent-societies-limitless-innovator") }}
          .col-6.col-md
            q-img(
              src="/img/pic5.webp",
              :ratio="1",
              contain,
              style="max-height: 200px"
            )
            .text-body2 {{ $t("get-a-chance-to-present-to-the-global-leaders-of-the-network") }}

  //- .row.justify-center.q-mt-xl(v-if="isOpen")
  //-   .q-pa-dynamic
  //-     h5 {{ $t('applications-close',[closeDate]) }} 
  .row.justify-center.q-mt-xl
    .q-pa-dynamic
      q-btn.q-mt-sm(
        align="between",
        no-caps,
        color="primary",
        size="lg",
        to="/academy",
        unelevated,
        style="width: 300px",
        :icon-right="matNorthEast"
      ) {{ $t("apply-now") }}
  //- .row.justify-center

  .row.q-mt-xl
    .hero2
      .topline {{ $t("together-we-make-a-difference") }}
      .bottomline {{ $t("the-future-of-our-planet-is-in-our-hands") }}

  .q-ma-dynamic.q-mt-xl
    .text-h2 {{ $t("looking-back-moving-forward") }}
    .text-body1 {{ $t("highlighted-innovators-from-limitless-2021") }}
    q-btn.q-pa-sm(
      :icon="matYouTube",
      flat,
      target="_BLANK",
      href="https://www.youtube.com/@SolferinoVoices",
      no-caps
    ) 
      |
      |
      |
      |
      |
      |
      |
      |
      |
      |
      .q-px-sm {{ $t("watch-all-content") }}

  .row.no-wrap.scroll.q-mt-xl.q-mb-xl.q-col-gutter-md
    .col-md-1.col-auto
    .col-md-5.col-10
      q-responsive(:ratio="16 / 9")
        video(
          controls,
          poster="//d3gj81j4mgprz9.cloudfront.net/assets/vid1.jpg"
        )
          source(src="//d3gj81j4mgprz9.cloudfront.net/assets/vid1.mp4")
    .col-md-5.col-10
      //- q-video(:ratio="16/9" src="/vid/vid2.mp4")
      q-responsive(:ratio="16 / 9")
        video(
          controls,
          poster="//d3gj81j4mgprz9.cloudfront.net/assets/vid2.jpg"
        )
          source(src="//d3gj81j4mgprz9.cloudfront.net/assets/vid2.mp4")
    .col-md-5.col-10
      //- q-video(:ratio="16/9" src="/vid/vid3.mp4")
      q-responsive(:ratio="16 / 9")
        video(
          controls,
          poster="//d3gj81j4mgprz9.cloudfront.net/assets/vid3.jpg"
        )
          source(src="//d3gj81j4mgprz9.cloudfront.net/assets/vid3.mp4")
    .col-md-5.col-10
      //- q-video(:ratio="16/9" src="/vid/vid3.mp4")
      q-responsive(:ratio="16 / 9")
        video(
          controls,
          poster="//d3gj81j4mgprz9.cloudfront.net/assets/vid4.jpg"
        )
          source(src="//d3gj81j4mgprz9.cloudfront.net/assets/vid4.mp4")
    .col-md-1.col-auto
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

// import Countdown from "@/components/Countdown";
import { auth } from "../db";
import Language from "@/mixins/Language";
import { DateTime } from "luxon";
import { signInWithEmailLink, isSignInWithEmailLink } from "firebase/auth";
import find from "lodash/find";
import includes from "lodash/includes";
import { fabYoutube } from "@quasar/extras/fontawesome-v5";

// import { scroll } from "quasar";
// const { getScrollTarget, setScrollPosition } = scroll;

/* eslint-disable no-control-regex */

export default {
  props: ["logo"],
  mixins: [Language],
  components: {
    // Countdown,
  },
  data() {
    return {
      logoswitch: true,
      eventslang: null,
      accessToken: null,

      loading: true,
      countDown: undefined,
      langSelected: false,
      snap: false,
      // workflowimages: ["ar", "en", "es", "fr"],
    };
  },
  computed: {
    launchVideoSrc() {
      let lang = "en";

      console.log(this.$i18n.locale);

      if (includes(["en", "es", "fr", "ar"], this.$i18n.locale)) {
        console.log("setting lang");

        lang = this.$i18n.locale;
      }

      console.log(lang);

      return `//d3gj81j4mgprz9.cloudfront.net/assets/promo/promo_${lang}.mp4`;
    },
    matYouTube() {
      return fabYoutube;
    },
    ...mapGetters(["getTagIcon"]),
    ...mapState(["user", "config"]),
    closeDate() {
      //find current_phase close date:

      // console.log(this.config.current_phase);

      if (this.config) {
        const ph = find(this.config.phases, {
          code: this.config.current_phase,
        });
        // console.log(DateTime.fromSeconds(ph.closesAt._seconds));

        return DateTime.fromSeconds(ph.closesAt._seconds).toLocaleString(
          DateTime.DATE_FULL
        );
      } else {
        return "...";
      }
    },
    hasLaunchVideo() {
      return process.env.VUE_APP_LAUNCH_VIDEO === "TRUE";
    },

    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    isOpen() {
      return process.env.VUE_APP_APPLICATIONS_OPEN === "TRUE";
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
  },
  async mounted() {
    const token = this.$route.params.token;
    if (token) {
      await this.signInAction({ token: token });
      this.$router.replace("/submission");
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      // console.log(email);
      if (email)
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            // Clear email from local storage
            window.localStorage.removeItem("emailForSignIn");
            return this.$router.replace("/submission");
          })
          .catch((error) => {
            // Clear email from local storage
            window.localStorage.removeItem("emailForSignIn");
            console.error(error);
            this.$q.notify({
              position: "top-right",
              color: "secondary",
              textColor: "white",
              icon: this.matWarning,
              message: `(${error.code}): ${this.$t("link_expired")}`,
            });
          });
      else
        this.$q.notify({
          position: "top-right",
          color: "secondary",
          textColor: "white",
          icon: this.matWarning,
          message: `(Please use this link on the same device on which you entered your email) ${this.$t(
            "link_expired"
          )}`,
        });
    }

    this.loading = false;

    // Get contributors

    await this.initConfig();

    // this.getHighlights();
    // this.getAcademy();
    // this.getStats();
  },
  methods: {
    onSelectChange(val) {
      if (val) this.langSelected = true;
      else this.langSelected = false;
    },
    noLangs(sessions) {
      let islang = false;
      sessions.forEach((element) => {
        if (element.langs.includes(this.eventslang)) islang = true;
      });
      return islang;
    },
    pastEvents(sessionAt) {
      return sessionAt <= DateTime.now();
    },
    isEventLang(session) {
      return session.langs.includes(this.eventslang);
    },
    getEventImg(event) {
      let ln = "en";
      // console.log(find(this.eventlangs, this.$i18n.locale));
      if (event.imgs.includes(this.$i18n.locale)) ln = this.$i18n.locale;
      return `/img/events/${ln}/${event.src}`;
    },
    // getWorkflowImg(layout) {
    //   // console.log(this.$i18n.locale);
    //   let lang = this.$i18n.locale;
    //   if (!includes(this.workflowimages, this.$i18n.locale)) lang = "en";
    //   // console.log(`/img/workflows/${layout}/${lang}.png`);
    //   return `/img/workflows/${layout}/${lang}.png`;
    // },
    // Region(user) {
    //   if (user.submissions.length)
    //     return this.getRegion(user.submissions[0].region);
    //   else return "";
    // },
    // getRegion(code) {
    //   return find(ifrcList, { code: code });
    // },

    // goto(ref) {
    //   const el = this.$refs[ref].$el;
    //   const target = getScrollTarget(el);
    //   const offset = el.offsetTop;
    //   const duration = 400;
    //   setScrollPosition(target, offset, duration);
    // },
    onIntersection(entry) {
      // console.log(entry);
      this.logo.display = !entry.isIntersecting;
    },

    ...mapActions([
      "signInAction",
      "googleSignInAction",
      "getStats",
      "getHighlights",
      // "getAcademy",
      "initConfig",
    ]),

    // signInWithLinkedIn() {
    //   window.location.href = `${process.env.VUE_APP_FUNCTION_URL}/redirect`;
    // },
  },
};
</script>

<style lang="scss"></style>

<style scoped lang="scss">
@import "@/styles/quasar.variables.scss";

h1.responsive {
  @media (max-width: $breakpoint-sm-max) {
    font-size: 2.4rem;
  }
}

@media only screen and (min-width: 600px) {
  .text-hero {
    font-size: 2.6em;
    font-weight: bold;
  }

  .text-title {
    font-weight: 400;
    font-size: 3.4em;
  }
}

@media only screen and (max-width: 600px) {
  .text-hero {
    font-size: 1.2em;
  }

  .text-title {
    font-weight: 400;
    font-size: 2.4em;
  }
}

a:link {
  color: $secondary;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: $secondary;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  // color: pink;
  background-color: transparent;
  text-decoration: underline;
}

.mainlogo {
  width: 70%;
}

.register {
  bottom: 1em;
  transform: translateX(-50%);
}

.liblue {
  // background: #0a66c2;
  color: white;
}

.title {
  font-size: 1.8em;
  text-transform: uppercase;
  font-style: italic;
  .text-caption {
    font-size: 0.6em;
  }
}

.dots {
  margin-top: 15px;
}

.border-dotted {
  border-top: 1px dashed $grey-6;
}

.herobg {
  background-image: url("/img/home_bg.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 59vw;
  min-height: 500px;
}

.hero1 {
  position: absolute;
  color: $secondary;
  // top: 0;
  top: 53%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-31%);
  background-image: url("/img/title_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 320px;
  height: 230px;
}

.hero2 {
  background-image: url("/img/hero2.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100vw;
  height: 38.5vw;
  position: relative;

  .topline {
    position: absolute;
    font-size: 3vw;
    font-weight: 500;
    color: white;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(5.5deg);
    width: 100%;
  }

  .bottomline {
    position: absolute;
    font-size: 3vw;
    font-weight: 500;
    color: $accent;
    top: 63%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%) rotate(-5.2deg);
  }
}
</style>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>
